import type { FC } from 'react';
import { isEmpty, omitBy } from 'lodash-es';
import { Suspense, useMemo } from 'react';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { ListSkeleton, TableListErrorBoundary } from 'components';
import { usePagination, useRouteDataIsolator, useSearch } from 'hooks';
import LogsList from 'features/frontapp/components/LogsList';
import type { FrontappLogsFilters } from 'features/frontapp/api/types';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Close } from '@mui/icons-material';
import { FrontappLogStatuses, FrontappRelatedDocs } from 'features/frontapp';
import { Outlet } from 'react-router';
import { KbSelector } from 'features/embeddings';

const FrontappLogsRoute: FC = () => {
  const [status, setStatus] = useQueryParam('status', withDefault(StringParam, ''));
  const [indexes, setIndexes] = useQueryParam('indexes', withDefault(StringParam, ''));
  const [related_documents, setRelatedDocuments] = useQueryParam('related_documents', withDefault(StringParam, ''));
  const [message_id, messageIdValue, messageIdChange, messageIdReset] = useSearch('message_id');
  const [conversation_id, converIdValue, converIdChange, converIdReset] = useSearch('conversation_id');
  const [inbox_email, inboxEmailValue, inboxEmailChange, inboxEmailReset] = useSearch('inbox_email');
  const [sender_email, senderEmailValue, senderEmailChange, senderEmailReset] = useSearch('sender_email');

  const filterData = useMemo(
    () => ({ status, indexes, message_id, conversation_id, inbox_email, sender_email, related_documents }),
    [status, indexes, message_id, conversation_id, inbox_email, sender_email, related_documents]
  );

  const values = useMemo(
    () => ({ status, indexes, messageIdValue, converIdValue, inboxEmailValue, senderEmailValue, related_documents }),
    [status, indexes, messageIdValue, converIdValue, inboxEmailValue, senderEmailValue, related_documents]
  );

  const { isFilterApplied, resetFilters } = useMemo(
    () => ({
      isFilterApplied: Object.values(filterData).some(Boolean),
      resetFilters: () => {
        setStatus(undefined);
        setIndexes(undefined);
        setRelatedDocuments(undefined);
        messageIdReset();
        converIdReset();
        inboxEmailReset();
        senderEmailReset();
      },
    }),
    [filterData]
  );

  const pagination = usePagination([filterData]);
  const filters = useMemo<FrontappLogsFilters>(() => omitBy(filterData, isEmpty), [filterData]);

  const { data, listQuery } = useRouteDataIsolator(
    useMemo(() => ({ values, filters, pagination }), [values, filters, pagination]),
    { nestedLevel: 4 }
  );

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={3}>
          <KbSelector
            fullWidth
            size="small"
            value={indexes}
            emptyLabel="All indexes"
            onChange={(e) => setIndexes(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            label="Message id"
            value={data.values.messageIdValue}
            onChange={messageIdChange}
            InputProps={{
              endAdornment: data.values.messageIdValue ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={messageIdReset}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            label="Conversation id"
            value={data.values.converIdValue}
            onChange={converIdChange}
            InputProps={{
              endAdornment: data.values.converIdValue ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={converIdReset}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Inbox email"
            size="small"
            value={data.values.inboxEmailValue}
            onChange={inboxEmailChange}
            InputProps={{
              endAdornment: data.values.inboxEmailValue ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={inboxEmailReset}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            label="Sender email"
            value={data.values.senderEmailValue}
            onChange={senderEmailChange}
            InputProps={{
              endAdornment: data.values.senderEmailValue ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={senderEmailReset}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl size="small" fullWidth>
            <InputLabel>Related documents</InputLabel>
            <Select
              label="Related documents"
              value={data.values.related_documents}
              onChange={(e) => setRelatedDocuments(e.target.value as string)}
            >
              <MenuItem value={undefined}>Reset filter</MenuItem>
              <MenuItem value={FrontappRelatedDocs.none}>{FrontappRelatedDocs.none}</MenuItem>
              <MenuItem value={FrontappRelatedDocs.one}>{FrontappRelatedDocs.one}</MenuItem>
              <MenuItem value={FrontappRelatedDocs.several}>{FrontappRelatedDocs.several}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl size="small" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select label="Status" value={data.values.status} onChange={(e) => setStatus(e.target.value as string)}>
              <MenuItem value={undefined}>All statuses</MenuItem>
              <MenuItem value={FrontappLogStatuses.NEW}>{FrontappLogStatuses.NEW}</MenuItem>
              <MenuItem value={FrontappLogStatuses.SUCCEED}>{FrontappLogStatuses.SUCCEED}</MenuItem>
              <MenuItem value={FrontappLogStatuses.FAILED}>{FrontappLogStatuses.FAILED}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          {isFilterApplied && (
            <Button sx={{ ml: 2, alignSelf: 'center' }} onClick={resetFilters}>
              Reset filters
            </Button>
          )}
        </Grid>
      </Grid>
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <TableListErrorBoundary>
          <Suspense
            fallback={
              <ListSkeleton
                titles={[
                  { field: 'ID', width: 70 },
                  { field: 'Inbox id' },
                  { field: 'Message id' },
                  { field: 'Request id' },
                  { field: 'Conversation id', width: 120 },
                  { field: 'Inbox email' },
                  { field: 'Sender email' },
                  { field: 'Related doc', width: 80 },
                  { field: 'Status' },
                  { field: 'Created at' },
                  { field: 'Updated at' },
                  { field: 'Finished at' },
                  { field: '', width: 70 },
                ]}
                size={10}
              />
            }
          >
            <LogsList pagination={data.pagination} filters={data.filters} />
          </Suspense>
        </TableListErrorBoundary>
      </Paper>
      <Outlet context={{ listQuery }} />
    </>
  );
};

export default FrontappLogsRoute;
